import React, { useState } from 'react'
import classNames from 'classnames'

import ToggleLeftNavButton from 'components/common/toggleLeftNavButton'
import useLeftNavItems from 'components/navbar/hooks/useLeftNavItems'
import LeftNavItem from 'components/navbar/leftNavItem'
import useWindowResize from 'components/common/hooks/useWindowResize'
import useAdminLeftNavItems from 'components/navbar/hooks/useAdminLeftNavItems'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.navbar')

const LeftNavbar = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const navItems = useLeftNavItems()
  const { adminNavItems, hasAdminAccess } = useAdminLeftNavItems()
  const { isXXLScreen } = useWindowResize()

  const isExpanded = isOpen || isHovered

  return (
    <>
      {isXXLScreen && (
        <ToggleLeftNavButton
          isOpen={isOpen}
          onClick={() => setIsOpen(!isOpen)}
          useNewUi
          className={classNames({ hidden: isHovered })}
        />
      )}

      <div
        className={classNames('LeftNavbar', { isOpen: isExpanded })}
        onMouseEnter={() => !isOpen && setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {navItems.map(item => (
          <LeftNavItem key={item.label} {...item} isLeftNavOpen={isExpanded} />
        ))}

        {hasAdminAccess && (
          <>
            <hr />

            <span className='AdminLabel text-smallest'>{I18N('admin').toUpperCase()}</span>

            {adminNavItems.map(item => (
              <LeftNavItem key={item.label} {...item} isLeftNavOpen={isExpanded} />
            ))}
          </>
        )}
      </div>
    </>
  )
}

export default LeftNavbar
