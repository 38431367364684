import React, { useContext } from 'react'
import TicketStatusSelect from 'components/ticketing/ticketStatusSelect'
import { TicketStatusEnum } from 'types/ticketing/ticket'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import TicketingContext from 'components/ticketing/ticketingContext'

const I18N = i18nPath('views.ticketing.view_ticket_sidebar')

const AdminTicketStatusSelect = ({ ticket }) => {
  const { fetchCounts } = useContext(TicketingContext)
  const [start] = useApi(API.admin.ticketing.tickets.start, {
    toastSuccessMessage: I18N('ticket_successfully_saved'),
    toastErrorMessage: I18NCommon('generic_error'),
    updateEntitySlice: true,
    onSuccess: fetchCounts,
  })
  const [close] = useApi(API.admin.ticketing.tickets.close, {
    toastSuccessMessage: I18N('ticket_closed'),
    toastErrorMessage: I18NCommon('generic_error'),
    updateEntitySlice: true,
    onSuccess: fetchCounts,
  })
  const [reopen] = useApi(API.admin.ticketing.tickets.reopen, {
    toastSuccessMessage: I18N('ticket_reopened'),
    toastErrorMessage: I18NCommon('generic_error'),
    updateEntitySlice: true,
    onSuccess: fetchCounts,
  })
  const [reset] = useApi(API.admin.ticketing.tickets.reset, {
    toastSuccessMessage: I18N('ticket_successfully_saved'),
    toastErrorMessage: I18NCommon('generic_error'),
    updateEntitySlice: true,
    onSuccess: fetchCounts,
  })

  const handleStatusChange = (status: TicketStatusEnum | undefined) => {
    if (!status) return
    if (status === ticket.status) return

    if (status === TicketStatusEnum.New) {
      reset(ticket.id)
    } else if (status === TicketStatusEnum.InProgress && ticket.status === TicketStatusEnum.New) {
      start(ticket.id)
    } else if (status === TicketStatusEnum.InProgress && ticket.status === TicketStatusEnum.Closed) {
      reopen(ticket.id)
    } else if (status === TicketStatusEnum.Closed) {
      close(ticket.id)
    }
  }

  return (
    <div onClick={e => e.stopPropagation()}>
      <TicketStatusSelect
        optionIds={Object.values(TicketStatusEnum)}
        value={ticket.status}
        onChange={status => handleStatusChange(status as TicketStatusEnum | undefined)}
        isClearable={false}
      />
    </div>
  )
}

export default AdminTicketStatusSelect
