import React from 'react'
import { AnswerTypeEnum, SurveyAnswerType } from 'types/surveys/answer'
import { i18nMoment, i18nPath } from 'utils/i18nHelpers'
import DatePicker from 'components/form_fields/datePicker'

const I18N = i18nPath('views.admin.surveys')

interface BirthdayAnswerContentProps {
  answer?: Partial<SurveyAnswerType> | null
  updateAnswer: (changes: any) => void
}

const BirthdayAnswerContent = ({
  answer,
  updateAnswer,
}: BirthdayAnswerContentProps) => {
  const birthday = answer?.string ? i18nMoment(answer?.string) : undefined

  return (
    <div className='BirthdayAnswerContent'>
      <DatePicker
        className='mt-4'
        selected={birthday}
        onChange={e => updateAnswer({ string: e ? e.format() : null, type: AnswerTypeEnum.string })}
        isClearable
        showTimeSelect={false}
        dateFormat='MMMM Do'
        placeholderText={I18N('answers.your_answer_placeholder')}
        inputReadOnly={false}
      />
    </div>
  )
}

export default BirthdayAnswerContent
