import React, { useEffect, useState } from 'react'
import { useDebounce } from 'usehooks-ts'

import AsyncSearchInput from 'components/common/asyncSearchInput'
import CdnSvg from 'components/common/cdnSvg'
import useApi from 'components/common/hooks/useApi'
import useQueryParamState from 'components/common/hooks/useQueryParamsState'
import TicketStatusSelect from 'components/ticketing/ticketStatusSelect'
import { TicketStatusEnum, TicketType } from 'types/ticketing/ticket'
import API from 'services/api'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import TicketTable from 'components/ticketing/ticketTable'
import { ButtonNarrow } from 'components/common/buttons'
import ViewTicketSidebar from 'components/ticketing/view_ticket_sidebar/viewTicketSidebar'
import useDebouncedClose from 'hooks/shared/useDebouncedClose'
import { useSelector } from 'react-redux'
import entitySlice from 'redux/slices/entities'
import CreateTicketSidebar from 'components/ticketing/createTicketSidebar'
import TicketingContext from 'components/ticketing/ticketingContext'

const I18N = i18nPath('views.ticketing')

const TicketListPage = () => {
  const [status, setStatus] = useQueryParamState<TicketStatusEnum | undefined>({
    param: 'status',
    initialValue: undefined,
  })

  const [searchQuery, setSearchQuery] = useState('')
  const debouncedQuery = useDebounce(searchQuery, 500)

  const [ticketIds, setTicketIds] = useState([])
  const [fetchTickets, {
    isLoading, paginationData, meta,
  }] = useApi(API.ticketing.tickets.fetchAll, {
    updateEntitySlice: true,
    onSuccess: tickets => setTicketIds(tickets.map(ticket => ticket.id)),
  })

  const tickets = useSelector(entitySlice.selectors.getEntities('ticketingTicket', ticketIds))

  const searchParams = {
    ...paginationData,
    status,
    title: debouncedQuery,
    perPage: 10,
  }

  useEffect(() => {
    fetchTickets({ page: 1, ...searchParams })
  }, [debouncedQuery, status])

  const [selectedTicketNumber, setSelectedTicketNumber] = useQueryParamState<string | undefined>({
    param: 'ticketNumber',
    initialValue: undefined,
  })

  // we need a debounced removal because a user may click a different ticket (outside the sidebar so calling onClose)
  // and we don't want to remove the newly selected ticket in that case
  const [isClosingSidebar, onCloseSidebar] = useDebouncedClose(() => setSelectedTicketNumber(''))

  const [isCreateTicketSidebarOpen, setIsCreateTicketSidebarOpen] = useState(false)

  const removeTicket = (ticket: TicketType) => {
    setTicketIds(ticketIds.filter(ticketId => ticketId !== ticket.id))
    if (selectedTicketNumber === String(ticket.number)) {
      setSelectedTicketNumber('')
    }
  }

  return (
    <TicketingContext.Provider value={{ removeTicket, fetchCounts: () => {} }}>
      <div className='TicketListPage MyTicketsPage'>
        <header className='TicketListPage__header d-flex justify-content-between px-4 py-3'>
          <div className='d-flex align-items-center'>
            <h4 className='mb-0 mr-4'>{I18NCommon('my_tickets')}</h4>
            <AsyncSearchInput
              placeholder={I18N('search_by_title')}
              className='w-100'
              onKeyUp={setSearchQuery}
              icon={<CdnSvg src='/images/searchIcon.svg' />}
              minCharsToSearch={3}
              inputGroupClassName='mr-2'
            />
            <TicketStatusSelect
              value={status}
              onChange={setStatus}
              optionIds={[TicketStatusEnum.New, TicketStatusEnum.InProgress, TicketStatusEnum.Closed]}
            />
          </div>

          <div className='d-flex align-items-center'>
            <ButtonNarrow className='TicketListPage__CreateNewButton' onClick={() => setIsCreateTicketSidebarOpen(true)}>
              <CdnSvg src='/images/addPencilIcon.svg' className='mr-2' />

              {I18NCommon('create_new')}
            </ButtonNarrow>
          </div>
        </header>

        <main className='AdminContent m-0'>
          <TicketTable
            isLoading={isLoading}
            tickets={tickets}
            paginationData={paginationData}
            onPaginationClick={data => fetchTickets({ ...searchParams, ...data })}
            isAdminTable={false}
            setSelectedTicketNumber={setSelectedTicketNumber}
          />
        </main>
        <CreateTicketSidebar
          isOpen={isCreateTicketSidebarOpen}
          onClose={() => setIsCreateTicketSidebarOpen(false)}
          onCreate={fetchTickets}
        />
        <ViewTicketSidebar
          ticketNumber={selectedTicketNumber}
          onClose={onCloseSidebar}
          isOpen={!!selectedTicketNumber && !isClosingSidebar}
        />
      </div>
    </TicketingContext.Provider>
  )
}

export default TicketListPage
