import { AnswerTypeEnum, SurveyAnswerType } from 'types/surveys/answer'
import { QuestionTypeEnum, SurveyQuestionType } from 'types/surveys/question'

const isValidAnswer = (answer: SurveyAnswerType | undefined, question: SurveyQuestionType) => {
  if (!answer || !question) {
    return false
  }

  const optionSelections = answer?.optionSelections || []

  switch (question.type) {
  case QuestionTypeEnum.ShortText:
  case QuestionTypeEnum.Paragraph:
  case QuestionTypeEnum.Html:
    return answer?.string?.trim() !== '' && answer.type === AnswerTypeEnum.string
  case QuestionTypeEnum.Checkbox:
    return optionSelections?.length > 0 && answer.type === AnswerTypeEnum.optionSelection
  case QuestionTypeEnum.MultipleChoice:
    return optionSelections?.length === 1 && answer.type === AnswerTypeEnum.optionSelection
  case QuestionTypeEnum.Scale:
    return !!answer?.number && answer.type === AnswerTypeEnum.number
  case QuestionTypeEnum.Birthday:
    return !!answer?.string && answer.type === AnswerTypeEnum.string
  default:
    return false
  }
}

export default isValidAnswer
