import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import FormEditableField from 'components/form_fields/formEditableField'
import { SurveyQuestionType, QuestionTypeEnum } from 'types/surveys/question'
import { SurveyAnswerType } from 'types/surveys/answer'
import Card from 'components/common/card'
import classNames from 'classnames'
import TextAnswerContent from 'components/admin/surveys/answers/textAnswerContent'
import MultipleOptionAnswerContent from 'components/admin/surveys/answers/multipleOptionAnswerContent'
import ScaleAnswerContent from 'components/admin/surveys/answers/scaleAnswerContent'
import QuestionTitleWithIndexBubble from 'components/admin/surveys/questions/questionTitleWithIndexBubble'
import { isOptionsQuestion, isTextQuestion } from 'utils/surveys/isValidQuestion'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import BirthdayAnswerContent from 'components/admin/surveys/answers/birthdayAnswerContent'

const I18N = i18nPath('views.admin.surveys')

interface AnswerCardProps {
  className?: string,
  question: SurveyQuestionType,
  answer?: Partial<SurveyAnswerType> | null,
  updateAnswer?: (changes: any) => void,
  isDisabled?: boolean,
  hasIndexBubble?: boolean,
  isEditing?: boolean,
}

export const AnswerContent = ({
  className = '',
  question,
  answer = null,
  updateAnswer = (changes: any) => { },
  isDisabled = false,
  isEditing = false,
} : AnswerCardProps) => {
  const questionType = question?.type
  const isText = isTextQuestion(question)
  const isBirthday = questionType === QuestionTypeEnum.Birthday
  const isMultipleOptionQuestion = isOptionsQuestion(question)
  const hasAnswer = !_.isEmpty(answer?.optionSelections) || !!answer?.number || !!answer?.string
  const isProfileFieldQuestion = !!question?.profileField
  const { appName } = useCurrentCompany()

  return (
    <div className={classNames('AnswerContent', className)}>
      {isProfileFieldQuestion && !isEditing && !isBirthday && (
        <div className='text-secondary'>
          {I18N('your_answer_will_display_in_your_profile', { appName })}
        </div>
      )}
      {isBirthday && (
        <BirthdayAnswerContent
          answer={answer}
          updateAnswer={updateAnswer}
        />
      )}
      {isText && (
        <TextAnswerContent
          question={question}
          answer={answer}
          updateAnswer={updateAnswer}
          isDisabled={isDisabled}
        />
      )}
      {isMultipleOptionQuestion && (
        <MultipleOptionAnswerContent
          question={question}
          answer={answer}
          updateAnswer={updateAnswer}
          isDisabled={isDisabled}
        />
      )}
      {questionType === QuestionTypeEnum.Scale && (
        <ScaleAnswerContent
          question={question}
          answer={answer}
          updateAnswer={updateAnswer}
          isDisabled={isDisabled}
        />
      )}
      {question.allowAdditionalText && hasAnswer && (
        <div className='mt-3'>
          <FormEditableField
            className='mt-4'
            label={<label className='font-weight-500'>{I18N('answers.additional_feedback')}</label>}
            currentValue={answer?.additionalText || ''}
            onChange={({ target }) => updateAnswer({ additionalText: target?.value })}
            isRequired={false}
            placeholder={I18N('answers.your_answer_placeholder')}
            disabled={isDisabled}
            textarea
          />
        </div>
      )}
    </div>
  )
}

const AnswerCard = ({
  className = 'my-4',
  question,
  answer = null,
  updateAnswer = (changes: any) => { },
  isDisabled = false,
  hasIndexBubble = true,
} : AnswerCardProps) => (
  <Card
    className={classNames('AnswerCard', className)}
    title={(
      <QuestionTitleWithIndexBubble
        hasIndexBubble={hasIndexBubble}
        question={question}
        questionNumber={(question?.orderPosition || 0) + 1}
      />
    )}
    cardBodyClassName='p-4'
  >
    <AnswerContent question={question} answer={answer} updateAnswer={updateAnswer} isDisabled={isDisabled} />
  </Card>
)

export default AnswerCard
