import React from 'react'
import { useParams } from 'react-router-dom'
import API from 'services/api'
import { Workflow } from 'services/api/admin/workflows'
import { WorkflowRun } from 'services/api/admin/workflows/runs'
import useFetch from 'components/common/hooks/useFetch'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import { TableLoadingContainer } from 'components/common/loadingContainer'
import SmartTable from 'components/common/tables/smartTable'
import { I18NCommon, i18nMoment, i18nPath } from 'utils/i18nHelpers'
import RoundedAvatars from 'components/common/roundedAvatars'
import CdnSvg from 'components/common/cdnSvg'
import DropdownMenuContainer from 'components/common/dropdownMenuContainer'
import CardDropdownMenuItem from 'components/common/cardDropdownMenuItem'
import DataList from 'components/common/dataList'
import TriggerableLabel from 'components/workflows/triggerableLabel'
import StatusLabel from 'components/workflows/statusLabel'

const I18N = i18nPath('views.admin.workflows.history_page')

const columns = [
  {
    header: I18N('table.headers.trigger'),
    accessor: run => <TriggerableLabel triggerableType={run.triggerableType} />,
  },
  {
    header: I18N('table.headers.requested_by'),
    accessor: run => (
      run.triggerable?.user ? (
        <RoundedAvatars users={[run.triggerable.user]} avatarSize='30px' />
      ) : (
        <span>{I18NCommon('unknown')}</span>
      )
    ),
  },
  {
    header: I18N('table.headers.started'),
    accessor: run => i18nMoment(run.createdAt).format('lll'),
  },
  {
    header: I18N('table.headers.status'),
    accessor: run => <StatusLabel status={run.status} />,
  },
  {
    header: I18N('table.headers.actions'),
    accessor: run => (
      <DropdownMenuContainer className='default-dropdown' menuType='kebab' closeMenuOnItemClick>
        <section>
          <CardDropdownMenuItem
            link={run.displayPath}
            primaryText={I18NCommon('show')}
            icon={<CdnSvg className='mr-2' src='/images/eyeOpenIcon.svg' />}
          />
        </section>
      </DropdownMenuContainer>
    ),
  },
]

const WorkflowPage = () => {
  const { workflowId } = useParams()

  const { data: workflow, isLoading } = useFetch<Workflow>(API.admin.workflows.fetch, [workflowId])
  const {
    data: runs,
    isLoading: isLoadingRuns,
    paginationData: { page, totalPages },
    callApi: fetchRuns,
  } = useFetch<WorkflowRun[]>(API.admin.workflows.runs.fetchAll, [workflowId])

  if (isLoading || !workflow) {
    return <CirclesLoadingIndicator />
  }

  return (
    <div className='WorkflowPage'>
      <header className='AdminHeader d-flex justify-content-between'>
        <div>
          <h4 className='mb-0'>{I18N('title')}</h4>
        </div>
      </header>

      <main className='AdminContent'>
        <DataList
          data={[
            { dataTitle: I18N('workflow_name'), dataValue: workflow.name },
            { dataTitle: I18N('workflow_description'), dataValue: workflow.description },
          ]}
        />

        <TableLoadingContainer isLoading={isLoadingRuns}>
          <SmartTable
            data={runs}
            showPagination={page !== null && totalPages !== null}
            columns={columns}
            page={page}
            pages={totalPages}
            onPaginationClick={fetchRuns}
            className='white-bg-table'
          />
        </TableLoadingContainer>
      </main>
    </div>
  )
}

export default WorkflowPage
